import { ReportConfigTabs } from 'types/report'
import { UITab } from 'types/ui'

export const normalizeUITabs = (tabsResponse: ReportConfigTabs): UITab[] => {
    return Object.keys(tabsResponse)
        .sort()
        .map((tabIndex: string) => {
            const tab = tabsResponse[tabIndex]
            return {
                label: tab.name.charAt(0).toUpperCase() + tab.name.slice(1),
                id: tab.pageName,
                filters: tab.filters,
                skipTimeFilter: tab.skipTimeFilter,
            }
        })
}
