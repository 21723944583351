import React, { useContext, useEffect, useMemo, useState } from 'react'
// PowerBI
import 'powerbi-report-authoring'
import { Embed, IReportEmbedConfiguration, models, Page, Report } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
// Contexts
import { AuthContext } from 'context/AuthContext'
import { ReportContext } from 'context/ReportContext'
// Components
import NavigationTabs from 'components/NavigationBar'
import AdditionalFilters from 'components/AdditionalFilters'
// Helpers
import { getInitialReportConfig, fetchSlicersAndStates, syncSlicers } from 'helpers/report'
import { normalizeUITabs } from './Dashboard.helpers'
// Types
import { Filter, ReportFilterType } from 'types/report'
import { UITab } from 'types/ui'
import { PDFSlicers } from 'types/pdfExport'
// Styles
import * as Styled from './Dashboard.styled'

const Dashboard = () => {
    const { embedURL: embedUrl, token: accessToken, tabs } = useContext(AuthContext)
    const initialReportConfig = getInitialReportConfig({ embedUrl, accessToken })
    const [reportConfig, setReportConfig] = useState<IReportEmbedConfiguration>(initialReportConfig)

    const [report, setReport] = useState<Report>()
    const [reportLoaded, setReportLoaded] = useState(false)
    const [reportSlicerStates, setReportSlicerStates] = useState<models.ISlicerState[]>([])
    const [pdfSlicers, setPdfSlicers] = useState<PDFSlicers>({})

    const normalizedTabs: UITab[] = useMemo(() => {
        const allTabs = normalizeUITabs(tabs)
        setReportConfig((prevReportConfig) => ({
            ...prevReportConfig,
            pageName: allTabs[0].id,
        }))
        return allTabs
    }, [tabs])

    useEffect(() => {
        report?.setAccessToken(accessToken).then(() => {
            report?.refresh()
        })
    }, [accessToken])

    useEffect(() => {
        report?.on('loaded', async () => {
            try {
                const filters = await getUpdatedFilters()
                await report.updateFilters(models.FiltersOperations.Replace, filters)
                await updatePdfCurrencySlicer()
            } catch (error) {
                console.log('PBI: Update filters error', error)
            }
            setReportLoaded(true)
        })

        report?.on('rendered', updateSlicersData)
    }, [report])

    const getUpdatedFilters = async () => {
        const activePage: models.IPage | undefined = await report?.getActivePage()
        const reportFilters = (await report?.getFilters()) as ReportFilterType[]
        const currentTabFilters: Filter[] = normalizedTabs.filter((tab) => tab.id === activePage?.name)[0].filters
        return reportFilters?.reduce((acc: ReportFilterType[], current: ReportFilterType) => {
            const currentTabsFilters: Filter | undefined = currentTabFilters?.find(
                (filter: Filter) => (current.target as models.IFilterColumnTarget)?.column === filter.name
            )

            if (currentTabsFilters) {
                if (currentTabsFilters.name.toUpperCase() === 'CURRENCY') {
                    current.operator = 'In'
                }

                current = {
                    ...current,
                    values: [currentTabsFilters.value],
                } as ReportFilterType
            }
            return [...acc, current]
        }, [])
    }

    const updatePdfCurrencySlicer = async () => {
        const reportFilters = await report?.getFilters()
        const currencyFilter = reportFilters?.find(
            (filter) => (filter.target as models.IFilterColumnTarget).column === 'Currency'
        )
        const currencyValue = (currencyFilter as models.IBasicFilter)?.values[0]
        if (currencyValue) {
            setPdfSlicers({ currencyFilter: currencyValue.toString() })
        }
    }

    const updateSlicersData = async () => {
        const activePage = await report?.getActivePage()
        const activeTab = normalizedTabs.find((tab) => tab.id === activePage?.name)
        const { slicerStates } = await fetchSlicersAndStates(report!)
        setReportSlicerStates(slicerStates)

        // Date Slicers
        if (!activeTab?.skipTimeFilter) {
            slicerStates.forEach((slicerState) => {
                const slicerTable = slicerState.targets?.[0].table
                const slicerColumn = (slicerState.targets?.[0] as models.IColumnTarget)?.column

                if (slicerTable === 'Time Period' && slicerColumn === 'Filter') {
                    const timePeriodFilter = (slicerState.filters[0] as models.IBasicFilter)?.values[0].toString()
                    setPdfSlicers((prevState) => ({ ...prevState, timePeriodFilter }))
                } else if (slicerTable === 'Calendar' && slicerColumn === 'Date') {
                    if (slicerState.filters.length) {
                        (slicerState.filters[0] as models.IAdvancedFilter).conditions?.forEach((condition) => {
                            if (condition.operator === 'GreaterThanOrEqual') {
                                const startDateFilter = convertTimeStampToISODate(condition.value as string)
                                setPdfSlicers((prevState) => ({ ...prevState, startDateFilter }))
                            } else if (condition.operator === 'LessThan') {
                                const endDateFilter = convertTimeStampToISODate(condition.value as string)
                                setPdfSlicers((prevState) => ({ ...prevState, endDateFilter }))
                            }
                        })
                    }
                } else if (slicerTable === 'account_relation' && slicerColumn === 'subgroup_label') {
                    const subGroupFilter = (slicerState.filters[0] as models.IBasicFilter)?.values?.[0].toString()
                    setPdfSlicers((prevState) => ({ ...prevState, subGroupFilter }))
                }
            })
        }
    }

    const updateDashboardSlicers = async (additionalSlicerStates: models.ISlicerState[]) => {
        const { slicers, slicerStates } = await fetchSlicersAndStates(report!)
        syncSlicers({
            targetedSlicers: slicers,
            targetedSlicerStates: slicerStates,
            sourcedSlicerStates: additionalSlicerStates,
        })
    }

    const convertTimeStampToISODate = (isoDateString: string) => {
        const date = new Date(isoDateString)
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    }

    const onChangeTab = async (tab: UITab) => {
        const pages: Page[] | undefined = await report?.getPages()
        const requestedPage: Page | undefined = pages?.find((page: Page) => page.name === tab.id)

        if (requestedPage) {
            return report?.setPage(requestedPage.name)
        }
    }

    const eventHandlersMap = new Map([
        [
            'loaded',
            function () {
                console.log('PBI: Report has loaded')
            },
        ],
        [
            'rendered',
            function () {
                console.log('PBI: Report has rendered')
            },
        ],
    ])

    return (
        <ReportContext.Provider value={{ pdfSlicers }}>
            {reportLoaded && <NavigationTabs tabs={normalizedTabs} onChangeTab={onChangeTab} />}
            {reportLoaded && (
                <AdditionalFilters
                    reportSlicerStates={reportSlicerStates}
                    updateDashboardSlicers={updateDashboardSlicers}
                />
            )}
            <Styled.DashboardWrapper>
                <Styled.PowerBIiFrame>
                    <PowerBIEmbed
                        embedConfig={reportConfig}
                        eventHandlers={eventHandlersMap}
                        cssClassName={'powerbi-iframe-embed'}
                        getEmbeddedComponent={(embedObject: Embed) => {
                            setReport(embedObject as Report)
                        }}
                    />
                </Styled.PowerBIiFrame>
            </Styled.DashboardWrapper>
        </ReportContext.Provider>
    )
}

export default Dashboard
